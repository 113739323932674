.nav .nav-item .nav-link .row .menuName {
  display: none;
}
.changingImg {
  transition: transform 0.3s ease;
  transform: translateX(0px);
}

.sidebar .sidebar-wrapper:hover {
  & .nav .nav-item .nav-link .row .menuName {
    display: inherit;
  }
  & .changingImg {
    transform: translateX(55px);
  }
}
