.autoComplete-wrapper {
  display: inline-block;
  width: 100%;

  & .autoComplete-parent {
    display: flex;
    flex-direction: column;
  }
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
  text-align: center;
  position: absolute;
  border: 1px solid #999;
  border-top-width: 0;
  margin-top: 2.5rem;
  max-height: 7.7rem;
  width: 22rem;
  z-index: 3;
  background-color: white;
}

.suggestions {
  z-index: 3;
  background-color: white;
  position: absolute;
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 2.5rem;
  max-height: 7.7rem;
  overflow-y: auto;
  padding-left: 0;
  width: 22rem;
}

.suggestions li {
  padding: 0.5rem;
  font-style: italic;
}

.suggestion-active,
.suggestions li:hover {
  background-color: rgba(235, 245, 255, 255);
  cursor: pointer;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}
