$padding-default-horizontal: 15px !default;

.card-calendar {
  .content {
    padding: 0 !important;
  }
  .fc-toolbar {
    padding-top: $padding-default-horizontal;
    padding-left: $padding-default-horizontal;
    padding-right: $padding-default-horizontal;
  }
  .fc td:first-child {
    border-left: 0;
  }
  .fc td:last-child {
    border-right: 0;
  }
  .fc-basic-view td:last-child.fc-week-number span,
  .fc-basic-view td:last-child.fc-day-number {
    padding-right: $padding-default-horizontal;
  }
  .fc .fc-day-header:last-child {
    padding-right: 15px;
  }
  .fc .fc-row:last-child td {
    border-bottom: 0;
  }
  .fc .fc-body .fc-widget-content {
    border-bottom: 0;
  }

  //custom styles
  & .fc-button.fc-prev-button,
  .fc-button.fc-next-button,
  .fc-button.fc-button-primary {
    background: black !important;
    border-color: black;
  }

  .fc-daygrid-day-top {
    cursor: pointer;
    > a {
      color: black !important;
      cursor: pointer;
      background-color: rgba($color: #ffffff, $alpha: 0);
    }
  }

  .fc-col-header-cell.fc-day > div {
    background-color: #d5d8dc;
    & > a {
      color: #17202a;
      font-weight: bolder;
    }
  }

  .fc-toolbar-title::first-letter {
    text-transform: capitalize;
  }

  .fc-day-today {
    background-color: #dcedf3 !important;
  }
}
