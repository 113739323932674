.animated-button {
  background-color: transparent;
  border: none;
  overflow: hidden;
  outline: none;
}

.animated-button-hover {
  border: none;
  overflow: hidden;
  outline: none;
  fill: white;
}

.animated-div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 3px solid;
}

.animated-button:focus {
  outline: none !important;
}

.animated-div-hover {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 3px solid;
  outline: none;
}

.disabled {
  color: gray;
}
