.buttonHover:hover {
  transform: scale(1.3);
  transition-duration: 0.2s;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown .buttonHover {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.dropdown {
  &.nav-item {
    .buttonHover {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
}
