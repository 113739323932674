.customBtnConfirm {
  font-weight: bold;
  background-color: #29a847;
  border-color: #28a745 !important;
  box-shadow: none !important;
  &:hover {
    background-color: #24933e !important;
    border-color: #24933e !important;
    box-shadow: grey 1px 1px !important ;
  }
  &:focus {
    background-color: #29a847;
    border-color: #28a745 !important;
    box-shadow: #27dd45 0px 0px 8px !important;
  }
}

.customBtnCancel {
  font-weight: bold;
  background-color: #cb0b0b;
  border-color: #cb0b0b !important;
  &:hover {
    background-color: #b20a0a;
    border-color: #b20a0a !important;
    box-shadow: grey 1px 1px;
  }
}

.customRowStyle > p {
  justify-content: space-evenly !important;
}
