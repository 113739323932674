.navbar {
  min-height: unset;
  max-height: unset;
  .btn,
  .navbar-brand,
  .navbar-nav .nav-item .nav-link {
    margin: unset;
    align-items: center;
  }
  .navbar-nav {
    .dropdown-toggle:after {
      margin-top: 0px;
    }
  }
}

.nav-tabs .nav-link {
  &.active {
    border-color: #dee2e6;
    border-radius: 3px;
  }

  &:active {
    border-color: #dee2e6;
    border-radius: 3px;
  }
  &:hover {
    border-color: #dee2e6;
    border-radius: 3px;
  }
  &:focus {
    border-color: #dee2e6;
    border-radius: 3px;
  }
}

@media (min-width: 992px) {
  .navbar {
    .navbar-nav {
      .nc-icon {
        margin-top: unset;
      }
      .notification {
        top: 0px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    padding-right: 15px;
    padding-left: 15px;
  }
}
