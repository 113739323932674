#unauthorizeContainer
{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom right, #EEE, #AAA);

  & {
    h1 {
      font-size: 40px;
      margin: 40px 0 20px;
      font-weight: bold;
    }
    p {
      font-size: 20px;
    }
  }
}