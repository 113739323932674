.grid-header-label {
  font-size: small;
  margin: 2px;
  font-weight: bold;
}
.grid-header-label:hover {
  color: #565656;
  cursor: pointer;
}

.grid-header-label-actions {
  font-size: small;
  margin-top: 0px;
  text-align: end;
}

.grid-header-input {
  font-size: small;
  background-color: #f7f7f8;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  box-shadow: none;
}

.gridView {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: #565656;
}

.gridView-pagination {
  border-radius: 0% !important;
  background-color: white !important;
  color: #23ccef !important;
  border: 1px groove rgba(35, 204, 239, 0.41) !important;
  border-radius: 10px !important;
}

.gridView-pagination-link {
  border-radius: 0% !important;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}
