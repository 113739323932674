.icon-button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.icon-button:hover {
  transform: scale(1.3);
  transition-duration: 0.2s;
}

.icon-button:focus {
  outline: none !important;
}

.disabled {
  color: gray;
}
